* {
  box-sizing: border-box;
}

.App {
  text-align: center;
  margin: 0 auto;
}

body,
html {
  color: #333;
  /* old background gradient */
  /* background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%); */
  background: #c3cfe2;
  height: 1000px;
}

nav {
  background: hsla(0, 0%, 100%, 0.8);
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 55px;
  text-align: right;
  font-size: 18px;
  box-shadow: 0px 1px 2px #777;
}

nav > ul > li {
  list-style: none;
  display: inline;
  padding: 5px;
  padding-right: 20px;
}

nav > ul > li > a {
  transition-property: color, font-weight;
  transition-duration: 0.1s;
  transition-timing-function: linear;
  font-family: 'Source Sans Pro', sans-serif;
  color: #666;
  font-weight: 400;
  text-decoration: none;
}

nav > ul > li > a:hover {
  color: #111;
  font-weight: 500;
}

main {
  width: 100%;
  height: 100%;
  bottom: 0;
  margin: 0 auto;
}

#home,
#about-me,
#projects,
#skills {
  height: 100%;
  display: flexbox;
  align-items: center;
  justify-content: center;
}

h1,
h2,
h3 {
  font-family: 'Libre Baskerville', serif;
}

p {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 18px;
  line-height: 30px;
}

header {
  width: 100%;
  margin: 0 auto;
  padding-top: 150px;
  padding-bottom: 125px;
  text-align: center;
}

header > img,
#resume-profile-image {
  height: 150px;
  border-radius: 100px;
  box-shadow: 0px 2px 4px #666;
  pointer-events: none;
}

#header-links,
#footer-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 25px;
}

#github-icon,
#linkedin-icon,
#email-icon {
  transition-property: color, text-shadow;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  color: #333;
  margin-right: 10px;
}

#github-icon:hover,
#email-icon:hover {
  color: #fefefe;
  text-shadow: 1px 1px 2px #999;
  cursor: pointer;
}

#footer-github-icon,
#footer-linkedin-icon,
#footer-email-icon {
  transition-property: color, text-shadow;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  color: #fefefe;
  margin-right: 10px;
}

#footer-github-icon:hover,
#footer-email-icon:hover {
  color: #333;
  text-shadow: 1px 1px 2px #999;
  cursor: pointer;
}

#linkedin-icon:hover,
#footer-linkedin-icon:hover {
  color: #0177b5;
  text-shadow: 1px 1px 2px #999;
  cursor: pointer;
}

#email-me-link > a,
#dogs-link {
  transition-property: color, text-shadow;
  transition-duration: 0.25s;
  transition-timing-function: linear;
  color: #333;
  font-weight: 600;
}

#dogs-link:hover {
  text-shadow: 1px 1px #fefefe90;
  color: #000;
}

#email-me-link > a:hover {
  text-shadow: 1px 1px #fefefe90;
  cursor: n-resize;
  color: #000;
  text-decoration-line: overline;
}

hr {
  border: 2px solid #f5f7fa;
  width: 50%;
}

section {
  margin: 0 auto;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 60px;
  text-align: center;
}

section > h3 {
  text-align: left;
  width: 60%;
  margin: 0 auto;
}

section > p {
  max-width: 60%;
  margin: 0 auto;
  text-align: left;
}

footer {
  width: 100%;
  text-align: center;
  padding: 15px;
  /* bottom: 0; */
  color: #fff;
  left: 0;
  height: 50px;
  margin-bottom: 75px;
}

#jquery {
  height: 1.3em;
  vertical-align: -webkit-baseline-middle;
}

.resume-page {
  margin-top: 75px;
  margin-bottom: 100px;
}

.resume-page > section > h1 {
  font-size: 32px;
}

#contact-list {
  padding-left: 0;
  list-style: none;
  font-size: 18px;
  font-weight: 600;
}

#contact-list > li {
  margin-top: 4px;
}

#skills-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
}

#skills-list > li {
  margin-top: 10px;
  margin-right: 25px;
}

#skills-list > li > i {
  transition-property: color, text-shadow;
  transition-duration: 0.25s;
  transition-timing-function: linear;
  color: #333;
}

#skills-list > li > #html5-icon:hover {
  color: #e44d27;
  text-shadow: 1px 1px 2px #777;
}

#skills-list > li > #css3-icon:hover {
  color: #264de5;
  text-shadow: 1px 1px 2px #777;
}

#skills-list > li > #js-icon:hover {
  color: #f0da4f;
  text-shadow: 1px 1px 2px #777;
}

#skills-list > li > #react-icon:hover {
  color: #60dafb;
  text-shadow: 1px 1px 2px #777;
}

#skills-list > li > #node-icon:hover {
  color: #24b45d;
}

#skills-list > li > #postgresql-icon:hover {
  color: #33658f;
}

#accessibility-icon:hover {
  color: #015a9c;
}

#skills {
  margin-bottom: 60px;
}

#clearance {
  color: #454545;
  text-shadow: 0.25px 0.25px 1px #999;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  body,
  html {
    height: 500px;
  }

  header {
    width: 60%;
  }

  nav > ul#nav-list > li {
    font-size: 16px;
  }

  nav {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
  }

  #resume-header {
    width: 80%;
  }

  #resume-header > h2 {
    font-size: 22px;
    color: #333;
  }
}

@media only screen and (max-width: 400px) {
  header > img {
    height: 125px;
    border-radius: 100px;
    box-shadow: 0px 2px 4px #666;
    pointer-events: none;
  }

  nav > ul#nav-list > li {
    font-size: 14px;
  }
}
