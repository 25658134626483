#refreshd-title, #mycongress-title, #dis-title, #learnjs-title {
  margin-top: 20px;
}

#refreshed-mobile-screencap, #mycongress-mobile-screencap, #dis-mobile-screencap, #learnjs-mobile-screencap {
  display: none;
}

#refreshed-screencap, #mycongress-screencap, #dis-screencap, #learnjs-screencap {
  transition-property: border, box-shadow;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  width: 45vw;
  margin-bottom: 5px;
  border-radius: 5px;
}

#mycongress-screencap {
  border: 2px solid #054272;
}

#refreshed-screencap {
  border: 2px solid #0D3918;
}

#learnjs-screencap {
  border: 2px solid #587B7F;
}

#dis-screencap {
  border: 2px solid #202D31;
}

#mycongress-screencap:hover {
  border: 2px solid #d50000;
  box-shadow: 1px 1px 5px #333;
}

#refreshed-screencap:hover {
  border: 2px solid #05A9A5;
  box-shadow: 1px 1px 5px #333;
}

#learnjs-screencap:hover {
  border: 2px solid #E2C045;
  box-shadow: 1px 1px 5px #333;
}

#dis-screencap:hover {
  border: 2px solid #47B462;
  box-shadow: 1px 1px 5px #333;
}

#projects {
  margin-top: 50px;
  width: 90%;
}

#projects > h1 {
  font-size: 30px;
}

.demo-button {
  margin-right: 6px;
}

.demo-button, .code-button {
  transition-property: border, background-color, color, box-shadow;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  font-size: 16px;
  border-radius: 6px;
  margin-top: 5px;
  padding: 8px;
  box-shadow: 1px 1px #333;
  background-color: #fefefe;
}

.demo-button:hover, .code-button:hover {
  background-color: #fff;
  border: 1px solid #999;
  color: #000;
  cursor: pointer;
  box-shadow: 2px 2px 2px #777;
}

.built-with {
  margin: 0 auto;
  text-align: left;
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
}

.built-with > li {
  margin-right: 10px;
  margin-top: 5px;
}

a.collab-name {
  transition-property: color, text-shadow;
  transition-duration: 0.25s;
  transition-timing-function: linear;
  color: #333;
  font-weight: 600;
}

a.collab-name:hover {
  text-shadow: 1px 1px #fefefe90;
  color: #000;
}

@media only screen and (max-width: 600px) {
  #refreshed-screencap, #mycongress-screencap,  #learnjs-screencap,  #dis-screencap {
    display: none;
  }

  #refreshed-mobile-screencap, #mycongress-mobile-screencap, #dis-mobile-screencap, #learnjs-mobile-screencap {
    display: block;
    margin: 0 auto;
    transition-property: border, box-shadow;
    transition-duration: 0.2s;
    transition-timing-function: linear;
    width: 60vw;
    margin-bottom: 5px;
    border-radius: 5px;
  }

  #mycongress-mobile-screencap {
    border: 2px solid #054272;
  }
  
  #refreshed-mobile-screencap {
    border: 2px solid #0D3918;
  }
  
  #learnjs-mobile-screencap {
    border: 2px solid #587B7F;
  }
  
  #dis-mobile-screencap {
    border: 2px solid #202D31;
  }
  
  .demo-button, .code-button {
    box-shadow: none;
  }
  
  .demo-button:hover, .code-button:hover {
    box-shadow: none;
  }
}